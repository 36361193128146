import * as React from 'react';
import Img from 'gatsby-image';
import { graphql, useStaticQuery } from 'gatsby';
import clsx from 'clsx';
import styles from './gallery.module.css';

function Gallery() {
  const data = useStaticQuery(graphql`
    query {
      screen: file(relativePath: { eq: "korobox-screen-10.png" }) {
        childImageSharp {
          fluid(maxWidth: 411) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <h4 className={styles.title}>Statistics</h4>
        <p className={styles.paragraph}>
          View a graph of calories consumed if you want to lose weight.
        </p>
      </div>
      <Img fluid={data.screen.childImageSharp.fluid} className={styles.screen} alt="" />
    </div>
  );
}

export default Gallery;
interface Props {
  screens: ReadonlyArray<any>;
}
export function Gallery2({ screens: [sc1, sc2] }: Props) {
  return (
    <div className={styles.floatContainer}>
      <div className={clsx(styles.fl, styles.text)}>
        <h4 className={styles.title}>Scanning the QR code</h4>
        <p className={styles.paragraph}>
          View the box contents right at the workplace by scanning the QR code of the box in your
          office.
        </p>
      </div>
      <Img
        fluid={sc1.childImageSharp.fluid}
        className={clsx(styles.screen, styles.screenRight)}
        alt=""
      />
      <Img
        fluid={sc2.childImageSharp.fluid}
        className={clsx(styles.screen, styles.screenRight)}
        style={{ marginBottom: 0 }}
        alt=""
      />
    </div>
  );
}

export function Gallery3({ screens: [sc1, sc2, sc3] }: Props) {
  return (
    <div className={styles.floatContainer}>
      <div className={clsx(styles.fl, styles.text)}>
        <h4 className={styles.title}>Search snacks</h4>
        <p className={styles.paragraph}>
          Search a snack by scanning a barcode or pick your favorite yummy from the box list.
        </p>
      </div>
      <Img
        fluid={sc1.childImageSharp.fluid}
        className={clsx(styles.screen, styles.screenRight)}
        style={{ marginTop: 0 }}
        alt=""
      />
      <Img
        fluid={sc2.childImageSharp.fluid}
        className={clsx(styles.screen, styles.screenLeft)}
        alt=""
      />
      <div style={{ clear: 'both' }} />
      <div className={clsx(styles.fl, styles.textLast)}>
        <h4 className={styles.title}>Buy snacks</h4>
        <p className={styles.paragraph}>
          Use cash or withdraw the money from your account balance to buy snacks.
        </p>
      </div>
      <Img
        fluid={sc3.childImageSharp.fluid}
        className={clsx(styles.screen, styles.screenRight, styles.screen3)}
        alt=""
      />
    </div>
  );
}

export function Gallery4({ screens: [sc1, sc2, sc3, sc4, sc5] }: Props) {
  return (
    <div className={styles.floatContainer}>
      <div className={clsx(styles.fr, styles.text)}>
        <h4 className={styles.title}>View statistics</h4>
        <p className={styles.paragraph}>
          Acquire full control over your spendings catch the moment when you either need to stop
          wasting your money or once you have to replenish the balance.
        </p>
      </div>
      <Img
        fluid={sc1.childImageSharp.fluid}
        className={clsx(styles.screen, styles.screenLeft)}
        alt=""
      />
      <Img
        fluid={sc2.childImageSharp.fluid}
        className={clsx(styles.screen, styles.screenRight)}
        alt=""
      />
      <Img
        fluid={sc3.childImageSharp.fluid}
        className={clsx(styles.screen, styles.screenLeft)}
        alt=""
      />
      <Img
        fluid={sc4.childImageSharp.fluid}
        className={clsx(styles.screen, styles.screenRight)}
        alt=""
      />
      <Img
        fluid={sc5.childImageSharp.fluid}
        className={clsx(styles.screen, styles.screenLeft)}
        alt=""
      />
    </div>
  );
}

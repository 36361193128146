import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
// styles
import clsx from 'clsx';
import styles from './korobox.module.css';
import itemStyles from '../components/item/cases.module.css';
// components
import Gallery, { Gallery2, Gallery3, Gallery4 } from './galery';
import AppLinks from './appLinks';
import Layout from '../components/item/layout';
import Resume from '../components/resume';
import Blockquote from '../components/blockquote';
import Schema from './schema2';
import Team from '../components/team';
// other
import { meta } from '../../../metaData';

function KoroboxCase() {
  const data = useStaticQuery(graphql`
    query {
      rodionSalnik: file(relativePath: { eq: "rodion-salnik.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 215, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen1: file(relativePath: { eq: "korobox-screen-1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen2: file(relativePath: { eq: "korobox-screen-2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen3: file(relativePath: { eq: "korobox-screen-3.png" }) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen4: file(relativePath: { eq: "korobox-screen-4.png" }) {
        childImageSharp {
          fluid(maxWidth: 385, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen5: file(relativePath: { eq: "korobox-screen-5.png" }) {
        childImageSharp {
          fluid(maxWidth: 411, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen6: file(relativePath: { eq: "korobox-screen-6.png" }) {
        childImageSharp {
          fluid(maxWidth: 1070, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen7: file(relativePath: { eq: "korobox-screen-7.png" }) {
        childImageSharp {
          fluid(maxWidth: 411, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen8: file(relativePath: { eq: "korobox-screen-8.png" }) {
        childImageSharp {
          fluid(maxWidth: 411, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen9: file(relativePath: { eq: "korobox-screen-9.png" }) {
        childImageSharp {
          fluid(maxWidth: 411, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen10: file(relativePath: { eq: "korobox-screen-10.png" }) {
        childImageSharp {
          fluid(maxWidth: 411, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen11: file(relativePath: { eq: "korobox-screen-11.png" }) {
        childImageSharp {
          fluid(maxWidth: 411, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen12: file(relativePath: { eq: "korobox-screen-12.png" }) {
        childImageSharp {
          fluid(maxWidth: 411, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen13: file(relativePath: { eq: "korobox-screen-13.png" }) {
        childImageSharp {
          fluid(maxWidth: 411, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen14: file(relativePath: { eq: "korobox-screen-14.png" }) {
        childImageSharp {
          fluid(maxWidth: 411, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen15: file(relativePath: { eq: "korobox-screen-15.png" }) {
        childImageSharp {
          fluid(maxWidth: 411, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen16: file(relativePath: { eq: "korobox-screen-16.png" }) {
        childImageSharp {
          fluid(maxWidth: 411, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen17: file(relativePath: { eq: "korobox-screen-17.png" }) {
        childImageSharp {
          fluid(maxWidth: 411, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen18: file(relativePath: { eq: "korobox-screen-18.png" }) {
        childImageSharp {
          fluid(maxWidth: 411, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen19: file(relativePath: { eq: "korobox-screen-19.png" }) {
        childImageSharp {
          fluid(maxWidth: 411, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen20: file(relativePath: { eq: "korobox-screen-20.png" }) {
        childImageSharp {
          fluid(maxWidth: 411, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const challengesList = [
    'Prepare the wireframes to provide a visual understanding of pages early in the project.',
    'Build an eye-pleasing design and an intuitive interface to ensure remarkable user experience.',
    'Implement both cash and cashless payment methods to let the users choose the one that fits them best.',
    'Create an admin panel for office managers so they can seamlessly monitor data and effectively manage snacks.',
  ];

  return (
    <Layout
      score={['Foodtech', 'Ukraine', 'September, 2019', 'Ongoing']}
      navBtn="#F88124"
      layoutClassName={styles.main}
      className={styles.header}
      contentClassName={styles.content}
      headerTitle="Meet Korobox&nbsp;─ cross-platform app for office snack service"
      subTitle="How we put our revolutionary idea in motion to help you delight your workforce with a digital-enabled honesty snacks box"
      metaData={meta.korobox}
    >
      <div className={itemStyles.inner}>
        <p className={itemStyles.preamble}>
          It all started with Rodion Salnik, the co-founder of Brocoders, who suggested the idea of
          creating an app for a snack box service. Initially, we wanted to cover our snacks box with
          a digital management platform that was meant for personal internal usage only. Later, we
          came to an agreement to develop a feature-rich solution that any company can download and
          utilize in order to improve the experience of buying snacks in the office.
        </p>
        <h2 className={itemStyles.title}>The situation</h2>
        <p className={itemStyles.paragraph}>
          As the name implies, the honesty snack box is a basket or a box full of snacks that allow
          employees not to waste their time, and have a snack before launch or during a coffee break
          right at the workplace.
        </p>
        <p className={itemStyles.paragraph}>
          Such an employee-centric approach demonstrates that you care deeply about your team
          members, and our company is not an exception here. But we decided to take a few steps
          further and enhance our snack boxes with an innovative solution that could make the
          workplace even comfier and more attractive, as well as make employees more loyal and
          satisfied.
        </p>
        <h3 className={itemStyles.subtitle}>Research</h3>
        <p className={itemStyles.paragraph}>
          To promote this concept and move on to the development stage, we started with in-depth
          research and created a poll to analyze the most thrilling issues related to a snack box
          service.
        </p>
        <p className={itemStyles.paragraph}>Here are some insights we managed to find out:</p>
        <ul className={clsx(itemStyles.bullets, styles.bulletsColor)}>
          <li>
            A lot of employees seem to forget to take cash when they go to a coffee corner so they
            seek the possibility to replenish the app balance and withdraw the money from it.
          </li>
          <li>
            Employees want a cashless payment method to be integrated so they can use their credit
            cards.
          </li>
          <li>Employees want to know how much money they spend on buying snacks.</li>
          <li>Employees want to know how many calories they consume.</li>
          <li>
            Employees want to see whether there are any snacks available without leaving their
            working place.
          </li>
          <li>
            Office managers need to view the overall sales statistics, as well as to track how many
            snacks have been eaten and how many left.
          </li>
        </ul>
        <Resume
          services={['mvp', 'product', 'mobileDev']}
          technology={['react', 'nodejs', 'reactNative']}
        />
      </div>
      <Blockquote
        img={data.rodionSalnik.childImageSharp.fluid}
        text="“The decision to digitalize the snack service in our office came by itself - it was the very case when you have to create a simple yet elegant solution at the same time. The one that would be pleasing and interesting to use when buying yummies.”"
        author={
          <React.Fragment>
            <b>Rodion Salnik</b>, <br />
            CEO & Co-Founder at Brocoders
          </React.Fragment>
        }
      />
      <div className={itemStyles.inner}>
        <h2 className={itemStyles.title}>Key challenges</h2>
        <p className={itemStyles.paragraph}>
          To bring this innovative idea to life and exceed the end-users’ expectations, we came up
          with the following tasks:
        </p>
        <div className={styles.challenges}>
          {challengesList.map((item, i) => (
            <div className={styles.item}>
              <div className={styles.count}>{i + 1}</div>
              <div className={styles.text}>{item}</div>
            </div>
          ))}
        </div>
        <p className={itemStyles.paragraph}>
          To meet the challenges above, we assembled a well-balanced team of the in-house seasoned
          experts that were ready to achieve the highest levels of productivity within a limited
          timeframe:
        </p>
        <Team
          color="#f57b25"
          list={[
            { count: 2, title: 'Backend\ndevelopers' },
            { count: 2, title: 'Frontend\ndevelopers' },
            { count: 1, title: 'QA\nengineer' },
            { count: 1, title: 'UI/UX\ndesigner' },
            { count: 1, title: 'Project\nmanager' },
          ]}
        />
      </div>
      <Schema />
      <section className={'section'}>
        <div className={styles.screen80}>
          <div className={clsx('inner')}>
            <div className={styles.group}>
              <Img
                fluid={data.screen4.childImageSharp.fluid}
                className={styles.screen4}
                style={{ flex: '1 1 70%' }}
                alt=""
              />
              <h2 className={styles.title80}>
                <b>80</b>
                <span>+</span>
                <br />
                Screens for iOS and Android
              </h2>
            </div>
          </div>
        </div>
        <div className={itemStyles.inner}>
          <p className={itemStyles.paragraph}>
            Once the wireframes stage was completed, we managed to determine the primary market
            sectors based on the requests of a few potential investors:
          </p>
          <Img fluid={data.screen3.childImageSharp.fluid} className={styles.screen3} alt="" />
          <h3 className={itemStyles.subtitle}>Payment System Integration</h3>
          <p className={itemStyles.paragraph}>
            Since we were mainly focused on the international market, our prior goal was to
            integrate a cashless settlement feature that would allow for a smooth and hassle-free
            app operation across the globe. To accomplish this task, we had to choose a payment
            processing platform empowered by a rock-solid security system.
          </p>
          <p className={itemStyles.paragraph}>
            That said, we ended up with Stipe — a highly competitive payments platform that provides
            small businesses, startups and entrepreneurs with easy-to-setup recurring payments.
            However, it took a long time to integrate Stripe due to its non-standard structure.
          </p>
          <p className={itemStyles.paragraph}>
            On top of that, we faced some extra challenges at this phase:
          </p>
          <ul className={clsx(itemStyles.bullets, styles.bulletsColor)}>
            <li>
              Since Korobox is basically a marketplace for buying and managing snacks, there were a
              lot of transactions to consider. We had to adjust and simplify a multi-layer payment
              system to allow for tracking the source, the date and the amount of transferred money.
            </li>
            <li>
              The amount of the fee charged by Stripe was almost the same as the price for a single
              snack. To keep transaction costs as low as possible, we had to bundle the payments
              locally by implementing a micropayments feature, according to which all the
              settlements are gathered within a week, and then sent to a box owner.
            </li>
            <li>
              To cover our expenses and the fee set by a Stipe platform, we had to charge a box
              owner with a 20% fee.
            </li>
          </ul>
          <Img fluid={data.screen5.childImageSharp.fluid} className={styles.screen5} alt="" />
          <h3 className={itemStyles.subtitle}>Admin panel for office managers</h3>
          <p className={itemStyles.paragraph}>
            At the first go-of, we provided office managers with a desktop-based tool for
            downloading and uploading the sheets that contained data on the quantity, the cost and
            the name of the products.
          </p>
          <p className={itemStyles.paragraph}>
            Once the admin panel was completed, office managers came up with the following standout
            features:
          </p>
          <ul className={clsx(itemStyles.bullets, styles.bulletsColor)}>
            <li>Enhanced management of snacks and users based on up-to-date info.</li>
            <li>Fill-up notifications for all users once new snacks are added into a box.</li>
            <li>Sales statistics monitoring and analyzing.</li>
            <li>
              A useful control tool for the “honesty box” content and quick addition of popular
              positions for the productive work of the entire team.
            </li>
          </ul>
        </div>
        <div className="inner">
          <Img fluid={data.screen6.childImageSharp.fluid} className={styles.screen6} alt="" />
        </div>
      </section>

      <div className={itemStyles.inner}>
        <h2 className={itemStyles.title}>Delivered solution</h2>
        <p className={itemStyles.paragraph}>
          Our number one goal was to increase the collaborative atmosphere spirit by providing a
          snack culture inside companies, reducing work interruptions and bringing up a sense of
          group responsibility and trust. We wanted to unload employees’ memories by keeping them on
          track with their spendings.
        </p>
        <p className={itemStyles.paragraph}>
          Driven by extensive expertise in developing innovative custom-designed solutions, we
          successfully extracted the immense power of the state-of-the-art technologies to overcome
          the challenges mentioned above. We managed to enrich our cross-platform app with some
          fascinating benefits that allow buyers to:
        </p>
        <Gallery2 screens={[data.screen11, data.screen12]} />
        <Gallery3 screens={[data.screen13, data.screen14, data.screen15]} />
      </div>
      <Img fluid={data.screen2.childImageSharp.fluid} className={styles.foolScreen} alt="" />

      <div className={clsx(itemStyles.inner, styles.gallery4)}>
        <Gallery4
          screens={[data.screen16, data.screen17, data.screen18, data.screen19, data.screen20]}
        />
        <Gallery />
      </div>
      <Img fluid={data.screen1.childImageSharp.fluid} className={styles.foolScreen} alt="" />

      <div className={itemStyles.inner}>
        <h2 className={itemStyles.title}>The results</h2>
        <p className={itemStyles.paragraph}>
          The Korobox solution proved to be a cutting-edge application that users love and enjoy
          using. At the moment, active development is suspended, yet there are a few scheduled
          improvements that would make the product even more advanced.
        </p>
      </div>
      <div className="inner">
        <div className={styles.imgGroup}>
          <Img fluid={data.screen7.childImageSharp.fluid} className={styles.screen7} alt="" />
          <Img fluid={data.screen8.childImageSharp.fluid} className={styles.screen7} alt="" />
          <Img fluid={data.screen9.childImageSharp.fluid} className={styles.screen7} alt="" />
        </div>
      </div>
      <div className={itemStyles.inner}>
        <p className={itemStyles.paragraph}>
          Among other crucial benefits, it is worth mentioning that office managers boosted and
          streamlined their workflow twice as fast, to say the least. Besides, company owners
          managed to win their employees’ trust and loyalty since they can now keep track of
          calories consumed and money spent via a smartphone or a tablet.
        </p>
        <p className={itemStyles.paragraph}>
          That said, whenever you decide to opt for such a product, we are always happy to meet new
          friends and adjust our solutions to your specific needs and requirements. Please feel free
          to contact us at any time.
        </p>
        <AppLinks />
      </div>
    </Layout>
  );
}

export default KoroboxCase;

import * as React from 'react';
import clsx from 'clsx';
import styles from './k-schema.module.css';

function Schema() {
  return (
    <section className={clsx('section', styles.container)}>
      <div className={clsx('inner', styles.inner)}>
        <h2 className={styles.title}>Wireframes and early feedback</h2>
        <p className={styles.paragraph}>
          We started with creating the wireframes that let us gather invaluable feedback from
          colleagues, stakeholders, potential partners and investors. We decided to opt for the
          wireframes since these are a great way to avoid additional expenses, minimize revisions,
          define a project’s structure and check the project’s viability in the first place.
        </p>
        <div className={styles.imageContainer}>
          <img
            src={require('./screen-1.png')}
            className={styles.screen1}
            srcSet={`${require('./screen-1.png')}, ${require('./screen-1@2x.png')} 2x, ${require('./screen-1@3x.png')} 3x,`}
            alt="Korobox app screen pic article"
          />
          <img
            src={require('./screen-2.png')}
            className={styles.screen2}
            srcSet={`${require('./screen-2.png')}, ${require('./screen-2@2x.png')} 2x, ${require('./screen-2@3x.png')} 3x,`}
            alt="Korobox app screen pic article"
          />
        </div>
        <img
          src={require('./screen-3.png')}
          srcSet={`${require('./screen-3.png')}, ${require('./screen-3@2x.png')} 2x, ${require('./screen-3@3x.png')} 3x,`}
          alt="Korobox app article list"
          className={styles.img}
        />
      </div>
    </section>
  );
}

export default Schema;

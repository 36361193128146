import * as React from 'react';
import styles from './applink.module.css';

function AppLinks() {
  return (
    <div className={styles.container}>
      <h4 className={styles.title}>Download Korobox app</h4>
      <div className={styles.group}>
        <a
          href="https://apps.apple.com/us/app/korobox/id1480389718?l=ru&ls=1"
          className={styles.appStore}
        />
        <a
          href="https://play.google.com/store/apps/details?id=com.brocoders.yummybox"
          className={styles.androidMarket}
        />
      </div>
      <a className={styles.link} href="https://korobox.live/">
        korobox.live
      </a>
    </div>
  );
}

export default AppLinks;
